import React, { useState } from 'react'
import styled from 'styled-components'

// import Img_logo1 from 'assets/img/contect-logo.svg'
// import Img_logo2 from 'assets/img/contect-logo1.svg'
import Img_logo from 'assets/img/contect-logo2.svg'
import Img_1_1 from 'assets/img/step-1-1.svg'
import Img_1_2 from 'assets/img/step-1-2.svg'
import Img_1_3 from 'assets/img/step-1-3.svg'
import Img_1_4 from 'assets/img/step-1-4.svg'
import Img_2_1 from 'assets/img/step-2-1.svg'
import Img_2_2 from 'assets/img/step-2-2.svg'
import Img_2_3 from 'assets/img/step-2-3.svg'
import Img_2_4 from 'assets/img/step-2-4.svg'
import dforce from 'assets/img/dforce.svg'
import loopfi from 'assets/img/saddle.svg'
import more from 'assets/img/loopfi.svg'

import bg_lines from 'assets/img/lines.png'
import bg_shapes from 'assets/img/rightGold.svg'




const Home: React.FC = () => {
    const [curIndex, setCurIndex] = useState(1)
    return (
        <Wrap>
            <StyledBG>
                <img src={bg_shapes} alt="" />
            </StyledBG>

            <Context>
                <StyledH1>How to Use Loopfi </StyledH1>

                <Select>
                    <Item className={curIndex === 1 ? 'isSeclected' : ''} onClick={() => { setCurIndex(1) }}>
                        veToken Stakers
                    </Item>
                    <Item className={curIndex === 2 ? 'isSeclected' : ''} onClick={() => { setCurIndex(2) }}>
                        LP Providers
                    </Item>
                </Select>

                {
                    curIndex === 1 ?
                        <>
                            <StepWrap>
                                <Step>
                                    <Title>STEP 01</Title>
                                    <StyledImg>
                                        <img src={Img_1_1} alt="" />
                                    </StyledImg>
                                    <Text>Deposit & stake veToken to receive pToken.  </Text>
                                </Step>

                                <Step>
                                    <Title>STEP 02</Title>
                                    <StyledImg>
                                        <img src={Img_1_2} alt="" />
                                    </StyledImg>
                                    <Text>Stake pToken to earn max boost on staking rewards and LPF token.</Text>
                                </Step>

                                <Step>
                                    <Title>STEP 03</Title>
                                    <StyledImg>
                                        <img src={Img_1_3} alt="" />
                                    </StyledImg>
                                    <Text>Claim your rewards at any time.  </Text>
                                </Step>

                                <Step>
                                    <Title>STEP 04</Title>
                                    <StyledImg>
                                        <img src={Img_1_4} alt="" />
                                    </StyledImg>
                                    <Text>Unstake pToken and sell for pegged token at any time.</Text>
                                </Step>
                            </StepWrap>
                        </>
                        :
                        <>
                            <StepWrap>
                                <Step>
                                    <Title>STEP 01</Title>
                                    <StyledImg>
                                        <img src={Img_2_1} alt="" />
                                    </StyledImg>
                                    <Text>Deposit & stake LP token to receive pLP token. </Text>
                                </Step>

                                <Step>
                                    <Title>STEP 02</Title>
                                    <StyledImg>
                                        <img src={Img_2_2} alt="" />
                                    </StyledImg>
                                    <Text>Stake pLP token to earn max boost on trading fees and staking rewards.</Text>
                                </Step>

                                <Step>
                                    <Title>STEP 03</Title>
                                    <StyledImg>
                                        <img src={Img_2_3} alt="" />
                                    </StyledImg>
                                    <Text>Claim your rewards at any time.  </Text>
                                </Step>

                                <Step>
                                    <Title>STEP 04</Title>
                                    <StyledImg>
                                        <img src={Img_2_4} alt="" />
                                    </StyledImg>
                                    <Text>Unstake pLP token and withdraw LP token at any time.</Text>
                                </Step>
                            </StepWrap>
                        </>
                }


                {/* *** *** *** *** 分割线 *** *** *** *** */}


                <StyledH1>DeFi Protocols Supported</StyledH1>

                {/* <StyledDeFi>
                    <StyledBGDefi>
                        <img src={bg_lines} alt="" draggable='false' />
                    </StyledBGDefi>

                    <LeftLogo>
                        <img src={Img_logo} alt="" />
                    </LeftLogo>

                    <ContextWrap>
                        <ContextText className='firstText'>
                            dForce advocates for building a complete set of DeFi protocols including assets, lending, trading, serving as DeFi infrastructure in Web3.
                        </ContextText>
                        <ContextText className='firstText'>
                            Saddle is a decentralized automated market maker protocol, optimized for pegged value crypto assets such as stablecoins and wrapped BTC.
                        </ContextText>
                    </ContextWrap>
                </StyledDeFi> */}

                <CardWrap>
                    <CardItem>
                        <LogoWrap>
                            <img src={dforce} alt="" />
                        </LogoWrap>
                        <StyledText>
                            dForce advocates for building a complete set of DeFi protocols including assets, lending, trading, serving as DeFi infrastructure in Web3.
                        </StyledText>
                        <StyledLink>
                            <a href="https://dforce.network/" target="_blank" rel="noopener noreferrer">
                                <img src={more} alt="" />
                            </a>
                        </StyledLink>
                    </CardItem>
                    <CardItem>
                        <LogoWrap>
                            <img src={loopfi} alt="" />
                        </LogoWrap>
                        <StyledText>
                            Saddle is a decentralized automated market maker protocol, optimized for pegged value crypto assets such as stablecoins and wrapped BTC.
                        </StyledText>
                        <StyledLink>
                            <a href="https://saddle.finance" target="_blank" rel="noopener noreferrer">
                                <img src={more} alt="" />
                            </a>
                        </StyledLink>
                    </CardItem>
                </CardWrap>
            </Context>
        </Wrap>
    )
}
export default Home


const Wrap = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;

    @media (max-width: 1119px) {
        padding-top: 60px;
    }
`
const StyledBG = styled.div`
    position: absolute;
    right: -60px;
    top: 80px;
    z-index: -1;

    @media (max-width: 1119px) {
        top: -310px;
        right: -130px;
    }

    img {
        @media (max-width: 1119px) {
            width: 200px;
        }
    }
`

const Context = styled.div`
    width: 1200px;
    margin: 0 auto;

    @media (max-width: 1119px) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
`

const StyledH1 = styled.div`
    font-size: 40px;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;

    @media (max-width: 1119px) {
        font-size: 18px;
    }
`

const Select = styled.div`
    width: 460px;
    height: 70px;
    border-radius: 35px;
    border: 1px solid #3A3524;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;

    @media (max-width: 1119px) {
        width: 260px;
        height: 35px;
    }
`

const Item = styled.div`
    width: 220px;
    height: 50px;
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    cursor: pointer;
    
    &.isSeclected {
        color: #010101;
        background: linear-gradient(180deg, #FFC741 0%, #FFDC4B 100%);
    }

    @media (max-width: 1119px) {
        width: 125px;
        height: 25px;
        font-size: 12px;
    }
`

const StepWrap = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 120px;

    @media (max-width: 1119px) {
        flex-wrap: wrap;
        padding-bottom: 60px;
    }
`

const Step = styled.div`
    width: 285px;
    background: #1A1A1A;
    border-radius: 20px;
    padding-bottom: 16px;

    @media (max-width: 1119px) {
        width: 48%;
        border-radius: 10px;
    }

    &:nth-child(3),
    &:nth-child(4) {
        @media (max-width: 1119px) {
            margin-top: 14px;
        }
    }
`

const Title = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: #CDCDCD;
    padding: 20px;

    @media (max-width: 1119px) {
        padding: 10px;
        padding-top: 16px;
        font-size: 14px;
    }
`

const StyledImg = styled.div`
    text-align: center;

    img {
        @media (max-width: 1119px) {
            width: 65px;
        }
    }
`
const Text = styled.div`
    width: 251px;
    margin: 0 auto;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 26px;

    @media (max-width: 1119px) {
        width: 90%;
        font-size: 14px;
        line-height: 22px;
    }
`

const StyledDeFi = styled.div`
    width: 1200px;
    height: 240px;
    background: linear-gradient(180deg, #0B0B0B 0%, #131212 100%);
    border-radius: 20px;
    display: flex;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 120px;
    position: relative;
    overflow: hidden;

    @media (max-width: 1119px) {
        flex-direction: column;
        width: 100%;
        height: auto;
        border-radius: 10px;
        padding-bottom: 20px;
        margin-bottom: 60px;
        margin-top: 30px;
    }
`

const CardWrap = styled.div`
    width: 1200px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 120px;

    @media (max-width: 1119px) {
        flex-direction: column;
        width: 100%;
        height: auto;
        padding-bottom: 20px;
        margin-bottom: 60px;
        margin-top: 30px;
    }
`

const CardItem = styled.div`
    width: 590px;
    height: 297px;
    background: #1A1A1A;
    border-radius: 20px;
    padding: 30px;

    @media (max-width: 1119px) {
        padding: 20px;
        height: auto;
        width: 100%;
        &:nth-child(2){
            margin-top: 20px;
        }
    }
`

const LogoWrap = styled.div`
    margin-bottom: 20px;
    text-align: center;

    img {
        width: 88px;
    }

    @media (max-width: 1119px) {
        margin-bottom: 10px;
        img {
            width: 60px;
        }
    }
`

const StyledText = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: #CDCDCD;
    line-height: 32px;

    @media (max-width: 1119px) {
        font-size: 14px;
        line-height: 22px;
    }
`

const StyledLink = styled.div`
    display: flex;
    flex-direction: row-reverse;
    img {
        width: 34px;
    }
    @media (max-width: 1119px) {
        img {
            width: 26px;
        }
    }
`

const StyledBGDefi = styled.div`
    position: absolute;
    left: 0;

    @media (max-width: 1119px) {
        left: -140px;
    }
`

const LeftLogo = styled.div`
    padding-left: 240px;
    padding-top: 72px;
    width: 420px;

    @media (max-width: 1119px) {
        padding-left: 0px;
        padding-top: 25px;
        width: 100%;
        text-align: center;
    }

    img {
        @media (max-width: 1119px) {
            width: 54px;
        }
    }
`

const ContextWrap = styled.div`
    flex: 1;
    padding-top: 20px;
`

const ContextText = styled.div`
    font-size: 20px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 32px;
    margin-top: 20px;
    width: 740px;

    &.firstText {
        font-size: 16px;
        font-weight: 400;
        color: #cecece;
    }

    @media (max-width: 1119px) {
        font-size: 10px;
        width: 90%;
        margin: 0 auto;
        line-height: 18px;
        margin-top: 10px;

        &.firstText {
            font-size: 9px;
        }
    }
`



