import React from 'react'
import styled from 'styled-components'

import Img_logo from 'assets/img/logo.svg'
import Img_stake from 'assets/img/stake-bj.svg'
import Img_twitter from 'assets/img/Twitter.svg'
import Img_forum from 'assets/img/Forum.svg'
// import Img_tele from 'assets/img/Telegram.svg'
import Img_github from 'assets/img/Github.svg'
import Img_discord from 'assets/img/Discord.svg'
import Img_medium from 'assets/img/Medium.svg'

import bg_shapes from 'assets/img/bg-shapes.svg'




const Home: React.FC = () => {
    return (
        <Wrap>
            <StyledBG>
                <img src={bg_shapes} alt="" />
            </StyledBG>

            <Context>
                <StyledH1>Join the Loopfi Community</StyledH1>

                <ContactWrap>
                    <Contact>
                        <a href="https://twitter.com/0xloopfi" target="_blank" rel="noopener noreferrer">
                            <LinkImg>
                                <img src={Img_twitter} alt="" />
                            </LinkImg>
                            <LinkName>Twitter</LinkName>
                        </a>
                    </Contact>

                    <Contact>
                        <a href="https://forum.loopfi.io" target="_blank" rel="noopener noreferrer">
                            <LinkImg>
                                <img src={Img_forum} alt="" />
                            </LinkImg>
                            <LinkName>Forum</LinkName>
                        </a>
                    </Contact>

                    <Contact>
                        <a href="https://discord.com/invite/Ye7y8bYEJx" target="_blank" rel="noopener noreferrer">
                            <LinkImg>
                                <img src={Img_discord} alt="" />
                            </LinkImg>
                            <LinkName>Discord</LinkName>
                        </a>
                    </Contact>

                    <Contact>
                        <a href="https://github.com/loopfi-io" target="_blank" rel="noopener noreferrer">
                            <LinkImg>
                                <img src={Img_github} alt="" />
                            </LinkImg>
                            <LinkName>Github</LinkName>
                        </a>
                    </Contact>

                    <Contact>
                        <a href="https://medium.com/@Loopfi" target="_blank" rel="noopener noreferrer">
                            <LinkImg>
                                <img src={Img_medium} alt="" />
                            </LinkImg>
                            <LinkName>Medium</LinkName>
                        </a>
                    </Contact>
                </ContactWrap>


                {/* *** *** *** *** 分割线 *** *** *** *** */}


                <StakeWrap>
                    <AbsImg>
                        <img src={Img_stake} alt="" draggable="false" />
                    </AbsImg>

                    <Title>
                        Boost your DeFi yields from all worlds via Loopfi.
                    </Title>

                    <BTNWRAP>
                        <BTN>
                            {/* <div>Coming Soon</div> */}
                            <a rel="noopener noreferrer" href='https://app.loopfi.io/' target='_blank'>
                                Stake to dForce
                            </a>
                        </BTN>

                        <BTN className='BTN2'>
                            <div>Coming Soon</div>
                            <a rel="noopener noreferrer" href='https://saddle.loopfi.io/' target='_blank'>
                                Stake to Saddle
                            </a>
                        </BTN>
                    </BTNWRAP>
                </StakeWrap>


                {/* *** *** *** *** 分割线 *** *** *** *** */}


                <Logo>
                    <img src={Img_logo} alt="" />
                </Logo>
            </Context>
        </Wrap>
    )
}
export default Home


const Wrap = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
`

const StyledBG = styled.div`
    position: absolute;
    left: 0;
    top: 96px;
    z-index: -1;

    @media (max-width: 1119px) {
        top: 20px;
    }
`

const Context = styled.div`
    width: 1200px;
    margin: 0 auto;

    @media (max-width: 1119px) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
`

const StyledH1 = styled.div`
    font-size: 40px;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;

    @media (max-width: 1119px) {
        font-size: 18px;
    }
`

const ContactWrap = styled.div`
    padding-top: 80px;
    padding-bottom: 120px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1119px) {
        flex-wrap: wrap;
        padding-top: 30px;
        padding-bottom: 60px;
    }
`

const Contact = styled.div`
    width: 230px;
    height: 149px;
    background: linear-gradient(180deg, #0D0D0D 0%, #1A1A1A 100%);
    border-radius: 20px;
    /* border-image: linear-gradient(180deg, rgba(30, 30, 27, 1), rgba(1, 1, 1, 1)) 2 2; */

    @media (max-width: 1119px) {
        width: 48%;
        border-radius: 10px;
        height: auto;
        padding-bottom: 10px;
    }

    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(4) {
        @media (max-width: 1119px) {
            margin-top: 14px;
        }
    }
`

const LinkImg = styled.div`
    padding-top: 30px;
    text-align: center;

    @media (max-width: 1119px) {
        padding-top: 20px;
    }

    img {
        @media (max-width: 1119px) {
            width: 45px;
        }
    }
`

const LinkName = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: #CACACA;
    text-align: center;
    line-height: 32px;

    @media (max-width: 1119px) {
        font-size: 14px;
    }
`

const StakeWrap = styled.div`
    position: relative;
    height: 356px;

    @media (max-width: 1119px) {
        height: auto;
    }
`

const AbsImg = styled.div`
    position: absolute;
    width: 100%;

    img {
        @media (max-width: 1119px) {
            width: 100%;
        }
    }
`

const Title = styled.div`
    padding-left: 60px;
    padding-top: 55px;
    width: 647px;
    font-size: 36px;
    font-weight: bold;
    color: #010101;
    line-height: 48px;
    position: relative;

    @media (max-width: 1119px) {
        width: 236px;
        padding-left: 20px;
        padding-top: 6px;
        font-size: 14px;
        line-height: 18px;
    }
`

const BTNWRAP = styled.div`
    display: flex;
    margin-top: 26px;
    margin-left: 60px;

    @media (max-width: 1119px) {
        margin-top: 12px;
        margin-left: 20px;
    }
`

const BTN = styled.div`
    width: 170px;
    height: 56px;
    position: relative;
    background-color: #010101;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;

    div {
        display: none;
    }

    &.BTN2 {
        margin-left: 20px;

        &:hover {
            a {
                display: none;
            }
            div {
                height: 100%;
                display: flex;
                color: #FFFFFF;
                justify-content: center;
                align-items: center;
            }
        }
    }

    a {
        width: 100%;
        height: 100%;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 1119px) {
        width: 120px;
        height: 30px;
        font-size: 12px;
        border-radius: 4px;

        &.BTN2 {
            margin-left: 10px;
        }
    }
`

const Logo = styled.div`
    text-align: center;
    padding-top: 60px;
    padding-bottom: 80px;

    @media (max-width: 1119px) {
        padding-top: 40px;
        padding-bottom: 30px;
    }

    img {
        @media (max-width: 1119px) {
            width: 100px;
        }
    }
`




