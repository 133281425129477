import React from 'react'
import styled from 'styled-components'

import Img1 from 'assets/img/img_1.svg'
import Img2 from 'assets/img/img_2.svg'



const Home: React.FC = () => {
    return (
        <Wrap>
            <Context>
                <Box>
                    <BoxLeft>
                        <img src={Img1} alt="" />
                    </BoxLeft>
                    <BoxRight>
                        <Title1>veTOKEN STAKERS</Title1>
                        <Title2>Put your staked token to work</Title2>
                        <Text>Stake through Loopfi will let you earn max boost on staking without having to lock up your veToken. Staked tokens will be pooled together and locked into supported protocols to receive optimized yield.   </Text>
                        <Text>Users will receive LPF token on top of veToken rewards and other platform fees.</Text>
                    </BoxRight>
                </Box>

                <Box className='secondBox'>
                    <BoxLeft>
                        <img src={Img2} alt="" />
                    </BoxLeft>
                    <BoxRight>
                        <Title1>LIQUIDITY PROVIDERS </Title1>
                        <Title2>Maximize you return</Title2>
                        <Text>Users can provide liquidity and stake LP tokens directly through Loopfi with minimal effort. Pooled tokens from stakers will let you get the maximum boost on the liquidity you provide.</Text>
                        <Text>Users may receive LPF token on top of trading fees and veToken rewards.</Text>
                    </BoxRight>
                </Box>
            </Context>
        </Wrap>
    )
}
export default Home


const Wrap = styled.div`
    width: 100%;
    height: 330px;
    background: linear-gradient(180deg, #161515 0%, #010101 100%);
    /* border: 2px solid; */
    /* border-image: linear-gradient(180deg, rgba(37, 36, 34, 1), rgba(1, 1, 1, 1)) 2 2; */
    margin-bottom: 120px;

    @media (max-width: 1119px) {
        height: auto;
        margin-bottom: 0px;
    }
`

const Context = styled.div`
    width: 1200px;
    margin: 0 auto;
    display: flex;
    padding-top: 40px;
    justify-content: space-between;

    @media (max-width: 1119px) {
        padding-top: 24px;
        flex-direction: column;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
`

const Box = styled.div`
    display: flex;
    width: 570px;

    @media (max-width: 1119px) {
        width: 100%;
    }

    &.secondBox {
        @media (max-width: 1119px) {
            margin-top: 20px;
        }
    }
`

const BoxLeft = styled.div`
    width: 120px;

    @media (max-width: 1119px) {
        width: 74px;
    }

    img {
        @media (max-width: 1119px) {
            width: 54px;
        }
    }
`

const BoxRight = styled.div`
    flex: 1;
`

const Title1 = styled.div`
    font-size: 22px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 50px;

    @media (max-width: 1119px) {
        font-size: 14px;
        line-height: 30px;
    }
`

const Title2 = styled.div`
    font-size: 20px;
    font-weight: 500;
    color: #CACACA;
    line-height: 26px;

    @media (max-width: 1119px) {
        font-size: 12px;
        line-height: 20px;
    }
`

const Text = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: #A0A0A0;
    line-height: 26px;
    margin-top: 12px;

    @media (max-width: 1119px) {
        font-size: 10px;
        margin-top: 4px;
        line-height: 18px;
    }
`

