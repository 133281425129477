import React from 'react'
import UnderBannar from 'views/UnderBannar'
import How from 'views/How'
import Join from 'views/Join'
import Top from 'views/Top'


const App: React.FC = () => {

  return (
    <>
      <Top />
      <UnderBannar />
      <How />
      <Join />
    </>
  )
}

export default App
