import React, { useState, createRef, useEffect } from 'react'
import styled from 'styled-components'

import Img_logo from 'assets/img/logo.svg'
import Img_arrow from 'assets/img/arrow.svg'
import Img_menu from 'assets/img/menu.svg'
import Img_close from 'assets/img/close.svg'
import bg_pc from 'assets/img/bannar-pc.png'
import bg_h5 from 'assets/img/bannar-h5.png'





const Home: React.FC = () => {
    const [showMenu, setShowMenu] = useState(false)
    const ref = createRef()

    // const forbid = (e: any) => {
    //     e.preventDefault && e.preventDefault();
    //     e.returnValue = false;
    //     e.stopPropagation && e.stopPropagation();
    //     return false;
    // }
    useEffect(() => {
        if (ref.current) {
            console.log(ref.current)
            const Dom: any = ref.current
            Dom.addEventListener("touchmove", (e: any) => {
                console.log('111', ref.current)

                e.preventDefault && e.preventDefault();
                e.returnValue = false;
                e.stopPropagation && e.stopPropagation();
                return false;
            })
            Dom.addEventListener("mousewheel", (e: any) => {
                // console.log('111',ref.current)
                // return false

                e.preventDefault && e.preventDefault();
                e.returnValue = false;
                e.stopPropagation && e.stopPropagation();
                return false;
            })
        }
    }, [ref])

    return (
        <>
            <Wrap className='onPC'>
                <Context>
                    <TopMenu>
                        <Logo>
                            <img src={Img_logo} alt="" />
                        </Logo>

                        <MenuList>
                            <Menu href='https://forum.loopfi.io' target='_blank'>Governance</Menu>
                            <Menu href='https://docs.loopfi.io/' target='_blank'>Docs</Menu>
                            <Menu className='pcMenu'>
                                <span>Community</span>
                                <ListMenu>
                                    <ListMenuItem href='https://twitter.com/0xloopfi' target='_blank'>
                                        Twitter
                                    </ListMenuItem>
                                    <ListMenuItem href='https://discord.com/invite/Ye7y8bYEJx' target='_blank'>
                                        Discord
                                    </ListMenuItem>
                                    <ListMenuItem href='https://github.com/loopfi-io' target='_blank'>
                                        Github
                                    </ListMenuItem>
                                    <ListMenuItem href='https://medium.com/@Loopfi' target='_blank'>
                                        Medium
                                    </ListMenuItem>
                                </ListMenu>
                            </Menu>
                        </MenuList>
                    </TopMenu>

                    <StyledTitle>Multi-Chain DeFi Yield Booster</StyledTitle>
                    <SubTitle>Deposit through Loopfi to unlock staking liquidity and boost DeFi yields.</SubTitle>

                    <BTNWRAP>
                        <StyledBTN href='https://app.loopfi.io/' target='_blank'>
                            <span>Stake to dForce</span>
                            {/* <img src={Img_arrow} alt="" /> */}

                            {/* <div>Coming Soon</div> */}
                        </StyledBTN>

                        {/* <StyledBTN className='BTN2' href='https://saddle.loopfi.io/' target='_blank'> */}
                        <StyledBTN className='BTN2'>
                            <span>Stake to Saddle</span>
                            <div>Coming Soon</div>
                        </StyledBTN>
                    </BTNWRAP>
                </Context>
            </Wrap>


            <Wrap className='onH5'>
                <Context>
                    <TopMenu>
                        <Logo>
                            <img src={Img_logo} alt="" />
                        </Logo>

                        <OpenMenu>
                            <img src={Img_menu} alt="" onClick={() => { setShowMenu(true) }} />
                        </OpenMenu>
                    </TopMenu>

                    <StyledTitle>Multi-Chain DeFi Yield Booster</StyledTitle>
                    <SubTitle>Deposit through Loopfi to unlock staking liquidity and boost DeFi yields.</SubTitle>

                    <BTNWRAP>
                        <StyledBTN href='https://app.loopfi.io/' target='_blank'>
                            <span>Stake to dForce</span>
                            {/* <img src={Img_arrow} alt="" /> */}

                            {/* <div>Coming Soon</div> */}
                        </StyledBTN>

                        {/* <StyledBTN className='BTN2' href='https://saddle.loopfi.io/' target='_blank'> */}
                        <StyledBTN className='BTN2'>
                            <span>Stake to Saddle</span>
                            <div>Coming Soon</div>
                        </StyledBTN>
                    </BTNWRAP>
                </Context>
            </Wrap>


            {
                showMenu &&
                <MenuPopup ref={ref} >
                    <TopMenu>
                        <Logo>
                            <img src={Img_logo} alt="" />
                        </Logo>

                        <OpenMenu>
                            <img src={Img_close} alt="" onClick={() => { setShowMenu(false) }} />
                        </OpenMenu>
                    </TopMenu>

                    <MenuList>
                        <Menu href='https://forum.loopfi.io' target='_blank'>Governance</Menu>
                        <Menu href='https://docs.loopfi.io/' target='_blank'>Docs</Menu>
                        <Menu>
                            <span>Community</span>
                            <ListMenu>
                                <ListMenuItem href='https://twitter.com/0xloopfi' target='_blank'>
                                    Twitter
                                </ListMenuItem>
                                <ListMenuItem href='https://discord.com/invite/Ye7y8bYEJx' target='_blank'>
                                    Discord
                                </ListMenuItem>
                                <ListMenuItem href='https://github.com/loopfi-io' target='_blank'>
                                    Github
                                </ListMenuItem>
                                <ListMenuItem href='https://medium.com/@Loopfi' target='_blank'>
                                    Medium
                                </ListMenuItem>
                            </ListMenu>
                        </Menu>
                    </MenuList>
                </MenuPopup>
            }
        </>
    )
}
export default Home


interface PopupProps {
    ref?: any
}
const MenuPopup = styled.div<PopupProps>`
    display: none;

    @media (max-width: 1119px) {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: -100px;
        z-index: 999;
        background-color: #010101;
        padding-left: 30px;
        padding-right: 30px;
    }
`

const Wrap = styled.div`
    width: 100%;
    height: 713px;
    position: relative;
    overflow: hidden;
    background-image: url(${bg_pc});
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;

    &.onPC {
        @media (max-width: 1119px) {
            display: none;
        }
    }

    &.onH5 {
        display: none;
        @media (max-width: 1119px) {
            display: block;
            background-image: url(${bg_h5});
            background-size: 100%;
            height: 350px;
        }
    }
`

const Context = styled.div`
    width: 1200px;
    margin: 0 auto;

    @media (max-width: 1119px) {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }
`

const TopMenu = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
`

const Logo = styled.div`
    img {
        @media (max-width: 1119px) {
            width: 100px;
        }
    }
`

const OpenMenu = styled.div`
    img {
        width: 24px;
    }
`

const MenuList = styled.div`
`

const ListMenu = styled.div`
    display: none;
    position: absolute;
    left: 8px;
    top: 30px;
    width: 120px;
    background: #FDFEFE;
    box-shadow: 0px 0px 15px 0px rgba(35, 42, 73, 0.16);
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;

    @media (max-width: 1119px) {
        display: block;
        position: relative;
        width: 100%;
        background: none;
        padding-top: 0px;
        padding-bottom: 0px;
        left: 0px;
        top: -6px;
    }
`

const ListMenuItem = styled.a`
    display: block;
    text-align: center;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-weight: 500;
    color: #010101;

    &:hover {
        background: #f0f0f0;
        @media (max-width: 1119px) {
            background: none;
        }
    }

    @media (max-width: 1119px) {
        color: #ffffff;
        text-align: left;
        padding-left: 20px;
        height: 26px;
        line-height: 26px;
    }
`

const Menu = styled.a`
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 19px;
    padding-left: 20px;
    padding-right: 20px;

    @media (max-width: 1119px) {
        display: block;
        line-height: 50px;
    }

    &.pcMenu {
        position: relative;
        padding-bottom: 16px;
    }

    &.pcMenu:hover {
        ${ListMenu} {
            display: block;
        }
    }
`

const StyledTitle = styled.div`
    font-size: 64px;
    font-weight: bold;
    color: #FFC741;
    line-height: 88px;
    margin-top: 176px;

    @media (max-width: 1119px) {
        font-size: 32px;
        line-height: 52px;
        margin-top: 30px;
    }
`

const SubTitle = styled.div`
    width: 495px;
    font-size: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
    line-height: 32px;

    @media (max-width: 1119px) {
        width: 340px;
        line-height: 24px;
        font-size: 14px;
    }
`

const BTNWRAP = styled.div`
    margin-top: 60px;
    display: flex;
    @media (max-width: 1119px) {
        margin-top: 30px;
    }
`
const StyledBTN = styled.a`
    width: 170px;
    height: 56px;
    background: linear-gradient(0deg, #FFC741 0%, #FFDC4B 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #010101;
    border-radius: 6px;

    &.BTN2 {
        margin-left: 20px;

            &:hover {
                span, img {
                    display: none;
                }
                div {
                    display: block;
                }
            }
    }

    @media (max-width: 1119px) {
        height: 40px;
        &.BTN2 {
            margin-left: 10px;
        }
    }

    div {
        display: none;
    }
`



